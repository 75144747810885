<template>
    <div>
        <v-row>
            <v-col cols="12" v-if="value.items != undefined && value.items != null">
                <v-list avatar two-line class="pt-0 mt-0" v-if="!totalsonly">
                    <template v-for="item in value.items">
                        <v-list-item :key="item.id">
                            <v-list-item-avatar v-if="item.imgSrc != undefined && item.imgSrc != null">
                                <v-img :src="item.imgSrc" />
                            </v-list-item-avatar>
                            <v-list-item-content :style="productItemColor(item.id)">
                                <div>{{ itemName(item) }} <v-icon small @click="showImage(item.imgSrc)" v-if="item.imgSrc != undefined && item.imgSrc != null">mdi-camera</v-icon></div>
                                <div class="caption grey--text">{{ item.category }}</div>
                            </v-list-item-content>
                            <v-list-item-action :style="productItemColor(item.id)">
                                <div class="grey--text">{{ itemStyle(item) }}</div>
                                <div class="grey--text" :style="coatExist && itemCategoryId( item ) != 'C' && itemCategoryId( item ) != 'SH' ? 'text-decoration: line-through;' : ''">{{ formatMoney(item.price) }}</div>
                            </v-list-item-action>
                        </v-list-item>
                    </template>
                </v-list>

                <!-- Total -->
                <v-divider inset class="mr-5 mb-2" v-if="(showTotals || totalsonly) && value.items.length > 0 && !itemsonly"></v-divider>
                <div v-if="(showTotals || totalsonly) && value.items.length > 0 && !itemsonly" class="text-xs-right ma-3">
                    
                    <v-row no-gutters>
                        <!-- subtotal -->
                        <v-col cols="9">Subtotal</v-col>
                        <v-col cols="3" class="text-right">{{ formatMoney( subtotal ) }}</v-col>

                        <!-- extra items -->
                        <v-col cols="9" v-if="extraItems.length > 0">
                            Extra Items
                            <v-row no-gutters>
                                <template v-for="xi in extraItems">
                                    <v-col cols="10" offset="2" :key="xi.categoryId">
                                        <span class="caption grey--text">{{ xi.category }} x {{ xi.qty }}</span>
                                    </v-col>
                                </template>
                            </v-row>
                        </v-col>
                        <v-col cols="3" class="text-right" v-if="extraItems.length > 0">
                            {{ formatMoney( totalExtraItems ) }}
                            <v-row no-gutters style="border-right:2px solid grey;" class="pr-2 mr-2">
                                <template v-for="xi in extraItems">
                                    <v-col cols="12" :key="xi.categoryId">
                                        <span class="caption text-right">{{ formatMoney( xi.amount ) }}</span>
                                    </v-col>
                                </template>
                            </v-row>
                        </v-col>

                        <!-- damage waiver -->
                        <v-col cols="9">Damage Waiver</v-col>
                        <v-col cols="3" class="text-right">{{ formatMoney( damageWaiver ) }}</v-col>

                        <!-- delivery -->
                        <v-col cols="9" v-if="showDelivery && pickupLocation != null">
                            Delivery @ {{ pickupLocation.name }}
                        </v-col>
                        <v-col cols="3" class="text-right" v-if="showDelivery && pickupLocation != null">
                            {{ formatMoney( pickupLocation.charge ) }}
                        </v-col>

                        <!--  charges -->
                        <v-col cols="9" v-if="value.charges != null && value.charges.length > 0">
                            Extra Charge
                            <v-row no-gutters>
                                <template v-for="c in value.charges">
                                    <v-col cols="10" offset="2" :key="c.code">
                                        <span class="caption grey--text">{{ c.code }}</span>
                                    </v-col>
                                </template>
                            </v-row>
                        </v-col>
                        <v-col cols="3" class="text-right" v-if="value.charges != null && value.charges.length > 0">
                            {{ formatMoney( totalExtraCharge ) }}
                            <v-row no-gutters style="border-right:2px solid grey;" class="pr-2 mr-2">
                                <template v-for="c in value.charges">
                                    <v-col cols="12" :key="c.code">
                                        <span class="caption text-xs-right">{{ formatMoney( c.amount ) }}</span>
                                    </v-col>
                                </template>
                            </v-row>
                        </v-col>

                        <!-- promo codes -->
                        <v-col cols="9" v-if="value.promoCodes != null && value.promoCodes.length > 0">
                            Promo Codes
                            <v-row no-gutters>
                                <template v-for="pc in value.promoCodes">
                                    <v-col cols="10" offset="2" :key="pc.code">
                                        <span class="caption grey--text">{{ pc.code }}</span>
                                    </v-col>
                                </template>
                            </v-row>
                        </v-col>
                        <v-col cols="3" class="text-right" v-if="value.promoCodes != null && value.promoCodes.length > 0">
                            - {{ formatMoney( totalPromoDiscount ) }}
                            <v-row no-gutters style="border-right:2px solid grey;" class="pr-2 mr-2">
                                <template v-for="pc in value.promoCodes">
                                    <v-col cols="12" :key="pc.code">
                                        <span class="caption text-xs-right">- {{ formatMoney( pc.amount ) }}</span>
                                    </v-col>
                                </template>
                            </v-row>
                        </v-col>

                        <v-col cols="9">Tax</v-col>
                        <v-col cols="3" class="text-right">{{ formatMoney( tax ) }}</v-col>

                        <v-col cols="9" class="title font-weight-light mt-2">Total</v-col>
                        <v-col cols="3" class="title font-weight-light mt-2 text-right" :style="noChargeApplied ? 'text-decoration: line-through;' : ''">{{ formatMoney( total ) }}</v-col>

                        <v-col cols="9" class="title font-weight-light mt-2" v-if="noChargeApplied">
                            No Charge: <span class="body-1">{{ this.noCharge.noCharge }}</span></v-col>
                        <v-col cols="3" class="title font-weight-light mt-2 text-right" v-if="noChargeApplied">{{ formatMoney( 0 ) }}</v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
        <v-dialog v-model="imageDialog" width="300">
            <v-card>
                <v-card-text class="pa-3">
                    <v-img :src="dialogImgSrc" v-if="dialogImgSrc != undefined && dialogImgSrc != null"></v-img>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { _st } from '@/softech';
import { mapGetters, mapActions } from 'vuex';

export default {
    props: {
        value: { type: Object, default: () => { return {} } },
        showTotals: { type: Boolean, default: true },
        totalsonly: { type: Boolean, default: false },
        itemsonly: { type: Boolean, default: false },
        showDelivery: { type: Boolean, default: false },
        pickupLocation: { type: Object, default: null },
        noCharge: { type: Object, default: null },
        unavailableItems: { type: Array, default: () => { return []; } },
        storeId: { type: [String, Number], default: 0 },
    },
    data() {
        return {
            imageDialog: false,
            dialogImgSrc: null,
        }
    },
    computed: {
        ...mapGetters({
            stores          : 'stores/stores',
            extraItemsObj   : 'extraItems/extraItems',
            promoCodes      : 'promo/promoCodes'
        }),
        componentReady() {
            if( _st.isNU( this.value ) )
                return false;
            
            return true;
        },
        coatExist() {
            if( !this.componentReady )
                return false;
            if( this.value.items == undefined || this.value.items == null )
                return false;

            return this.value.items.filter(i => {
                return this.itemCategoryId( i ) == 'C';
            }).length > 0;
        },
        localSubtotal() {
            if( !this.componentReady )
                return 0;
            if( this.value.items == undefined )
                return 0;

            return this.mxSubtotal( this.value.items );
        },
        subtotal() {
            if( !this.componentReady )
                return 0;
            if( this.value.items == undefined )
                return 0;
            if( this.value.subtotalOverwrite != null && parseFloat(this.value.subtotalOverwrite) > 0 )
                return parseFloat(this.value.subtotalOverwrite);

            let subtotal = 0;

            this.value.items.forEach(i => {

                if( this.coatExist ) {
                    if( this.itemCategoryId( i ) == 'C' )
                        subtotal += parseFloat(i.price);
                }
                else
                    subtotal += parseFloat(i.price); 
            });

            return subtotal;
        },
        damageWaiver() {
            if( !this.componentReady )
                return 0;
            if( this.value.items == undefined )
                return 0;

            return this.value.items.length > 0 ? 10 : 0;
        },
        tax() {
            let store = this.stores.find(s => s.id == this.storeId);
            let tax = _st.isNUE( store ) ? 0.115 : store.tax;

            return ( this.subtotal + this.damageWaiver + this.totalExtraItems + ( this.showDelivery && this.pickupLocation != null ? parseFloat( this.pickupLocation.charge ) : 0 ) + this.totalExtraCharge - this.totalPromoDiscount ) * tax; // *** cambiar el rate del tax por una variable
        },
        totalPromoDiscount() {
            if( !this.componentReady )
                return 0;
            if( this.value.items == undefined )
                return 0;

            let total = 0;
            if( this.value.promoCodes != undefined ) {
                this.value.promoCodes.forEach(p => { 
                    total += parseFloat(p.amount);
                });
            }

            return total;
        },
        totalExtraCharge() {
            if( !this.componentReady )
                return 0;
            if( this.value.charges == null )
                return 0;

            let total = 0;
            this.value.charges.forEach(c => {
                total += parseFloat( c.amount );
            });

            return total;
        },
        extraItems() {
            if( !this.componentReady )
                return [];
            if( this.value.items == undefined || this.value.items == null )
                return [];

            let currentItems = [];
            let xtraItems = [];
            
            this.value.items.forEach(p => {
                // let product = this.products.find(ps => { return ps.id == p.id; });
                // if( product == undefined )
                //     return;

                let categoryId = this.itemCategoryId( p );
                if( currentItems.filter(i => { return i.categoryId == categoryId } ).length == 0 ) {
                    currentItems.push({
                        categoryId: categoryId,
                        category: p.category,
                        qty: 1
                    });
                }
                else {
                    let item = currentItems.find(i => {
                        return i.categoryId == categoryId;
                    });

                    item.qty++;
                }
            });

            currentItems.forEach(ci => {
                if( ci.qty > 1 ) {
                    let ei = this.extraItemsObj.find(eic => { return eic.categoryId == ci.categoryId });
                    let amount = _st.isNUE( ei ) ? 0 : ei.amount;
                    xtraItems.push({
                        categoryId: ci.categoryId,
                        category: ci.category,
                        qty: ci.qty - 1,
                        amount: (ci.qty - 1) * amount
                    });
                }
            });

            return xtraItems;
        },
        totalExtraItems() {
            if( !this.componentReady )
                return 0;

            let total = 0;
            this.extraItems.forEach(xi => {
                total += parseFloat(xi.amount);
            });

            return total;
        },
        total() {
            return this.subtotal + this.damageWaiver  + this.totalExtraItems + ( this.showDelivery && this.pickupLocation != null ? parseFloat( this.pickupLocation.charge ) : 0 ) + this.totalExtraCharge - this.totalPromoDiscount + this.tax;
        },
        noChargeApplied() {
            return !_st.isNUE( this.noCharge );
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions({
            loadStores      : 'stores/loadStores',
            loadExtraItems  : 'extraItems/loadExtraItems',
            loadPromoCodes  : 'promo/loadPromoCodes'
        }),
        async init() {
            if( _st.isNUE( this.stores ) )
                await this.loadStores();

            if( _st.isNUE( this.extraItemsObj ) )
                await this.loadExtraItems();

            if( _st.isNUE( this.promoCodes ) )
                await this.loadPromoCodes();
        },
        showImage(src) {
            this.dialogImgSrc = src;
            this.imageDialog = true;
        },
        getImgPath( src ) {
            var images = require.context('../assets/images/', false, /\.jpg$|\.JPG$|\.png$|\.PNG$/);
            return images( './' + src );
        },
        productItemColor(pid) {
            if( !this.unavailableItems.includes( pid ) )
                return '';

            return 'color:red;';
        },
        itemName(item) {
            return item.description??item.productName;
        },
        itemStyle(item) {
            return item.style??item.foreignId;
        },
        itemCategoryId(item) {
            return item.categoryId??item.categoryID;
        }
    },
}
</script>

<style>

</style>
